<template>
  <!-- <v-layout max-width class="flex-column white"> -->

  <v-layout class="flex-column white pt-3 pb-6">
    <!-- style="border-bottom:1px solid #e8e8e8;" -->
    <div class="flex-y-center flex-justify-between pb-2 px-4" style="border-bottom:1px solid #eee;">
      <div class=" flex-y-center">
        <v-btn dark small color="#00bea9" depressed min-width="32" height="30" width="100" class=" mr-2" style="padding:0 8px" @click="addShow">
          <v-icon size="16">mdi-plus</v-icon>新增角色
        </v-btn>
        <exportBtn :url="$api.exportRoleList" :parmas="{...search.data}" />
      </div>
      <div class=" flex-y-center">

        <div class="flex-y-center mr-1" style="width:120px;">
          <form-item
            v-model="search.data.roleCategory"
            class=" flex-0 flex-shrink-0"
            :options="[{text:'组织角色',value:'0'},{text:'项目角色',value:'1'}]"
            placeholder="角色分类"
            dense
            type="select"
            @input="changeType"
          />

        </div>
        <div class="flex-y-center mr-1" style="width:120px;">
          <form-item
            v-model="search.data.roleName"
            class="mr-0"
            placeholder="角色名称"
            type="text"
            allow-close
            @onEnter="initList()"
          />
        </div>
        <div class="flex-y-center mr-1" style="width:120px;">
          <form-item
            v-model="search.data.roleScope"
            class=" flex-0 flex-shrink-0"
            :options="roleScopeList"
            placeholder="权限范围"
            dense
            type="select"
            allow-close
            @input="$forceUpdate()"
            @select="(e)=>{search.data.roleScope = e.val,initList()}"
            @cleanVal="(e)=>{search.data.roleScope = e.val,initList()}"
          />

        </div>
        <div class="flex-y-center mr-1" style="width:120px;">
          <form-item
            v-model="search.data.statusFlag"
            class=" flex-0 flex-shrink-0"
            :options="[{text:'可用',value:'0'},{text:'禁用',value:'1'}]"
            placeholder="状态"
            style="width: 120px"
            dense
            type="select"
            allow-close
            @input="$forceUpdate()"
            @select="(e)=>{search.data.statusFlag = e.val,initList()}"
            @cleanVal="(e)=>{search.data.statusFlag = e.val,initList()}"
          />

        </div>
        <v-btn dark small color="primary" class="mr-1" depressed height="30" min-width="34" style="padding:0;" @click="initList()">
          <v-icon size="18">mdi-magnify</v-icon>
        </v-btn>
        <v-btn dark small color="grey lighten-1" depressed min-width="34" height="30" style=" padding:0;" @click="search.data.roleScope='',search.data.statusFlag='',initList()">
          <v-icon size="18">mdi-restore</v-icon>
        </v-btn>
        <!-- <v-btn depressed color="primary">搜索</v-btn> -->
      </div>

    </div>
    <div class="">
      <v-data-table
        :headers="headers"
        :items="list"
        :items-per-page="search.pageSize"
        class="pb-5 max-width  pa-4 header-grey"
        hide-default-footer
        :loading="listLoading"
        item-key="id"
        disable-sort
        no-data-text="暂无查询数据"
      >
        <!-- <template v-slot:item="{expand,item}"> -->

        <template v-slot:header.roleScope>
          {{ search.data.roleCategory==1?'项目负责人默认角色':'权限范围' }}
        </template>
        <template v-slot:item.rowIndex="{index}">
          {{ (index+1)+(search.pageSize*(search.pageNo-1)) }}
        </template>
        <template v-slot:item.roleScope="{item}">
          <template v-if="search.data.roleCategory==1">
            {{ item.defaultFlag==1?'是':'否' }}
          </template>
          <template v-else>
            <!-- 0:本人项目 1:部门项目 2:所有项目 -->
            {{ item.roleScope==1?'本部门负责项目':(item.roleScope==2?'所有项目':'本人参与项目') }}
          </template>
        </template>

        <template v-slot:item.statusFlag="{item}">
          <span :class="item.statusFlag==1?'error--text':''">{{ item.statusFlag==1?'禁用':'可用' }}</span>
        </template>
        <template v-slot:item.active="{item}">
          <div class=" flex-y-center">
            <template v-if="!((search.data.roleCategory=='0') && (item.roleName == '管理员'||item.roleName == '超级管理员'))">
              <v-icon color="primary" size="18" title="编辑" class=" mr-1" @click="showEdit(item)">mdi-square-edit-outline</v-icon>
              <!-- setRoleStatus -->

              <v-icon v-if="item.statusFlag==1" color="primary" size="18" class=" mr-1" title="恢复" @click="activeList($api.setRoleStatus,'post','恢复',{id:item.id,statusFlag:'0'})">mdi-replay</v-icon>
              <v-icon v-else color="error" size="18" class=" mr-1" title="禁用" @click="activeList($api.setRoleStatus,'post','禁用',{id:item.id,statusFlag:'1'})">mdi-cancel</v-icon>
              <v-icon color="error" size="20" title="删除" class=" mr-1" @click="activeList($api.roleDelete,'delete','删除',{id:item.id})">mdi-delete-outline</v-icon>
              <!-- <v-icon color="error" size="20" @click="showDel(item.id)" title="删除" class=" mr-1">mdi-delete-outline</v-icon> -->

              <v-icon color="primary" size="18" title="菜单授权" class=" mr-1" @click="showDrawer(item.id)">mdi-shield-check-outline</v-icon>
            </template>
            <v-icon v-if="search.data.roleCategory=='0'" color="primary" size="18" title="查看用户" class=" mr-1" @click="showUser(item)">mdi-account-multiple</v-icon>

            <v-icon v-else color="primary" size="18" title="报告授权" class=" mr-1" @click="showDrawer(item.id,'report')">mdi-file-alert-outline</v-icon>
            <!-- <a class=" mr-2" @click="showDrawer(item.id)">授权</a> -->

          </div>

          <!-- <a class=" mr-2" @click="showEdit(item)">编辑</a> -->
          <!-- <a class=" mr-2">禁用</a> -->
          <!-- <a class=" mr-2 error--text" @click="showDel(item.id)">移除</a>
          <a class=" mr-2" @click="showDrawer(item.id)">授权</a> -->
          <!-- <a class=" mr-2" @click="userDialog.text=item.rolename,userDialog.show=true">用户</a> -->
        </template>
      </v-data-table>
      <div class="mt-2 pr-2 mb-5 max-width  flex-justify-end">
        <pagination v-model="search.pageNo" :size="search.pageSize" :total="total" @input="getList" @changePageSize="changePageSize" />
      </div>
    </div>
    <!-- 新增 编辑 组织角色 -->
    <v-dialog v-model="dialog.show" width="600">
      <form v-if="dialog.show" class="max-height flex-column overflow-hidden" @submit.prevent="submitAdd()">
        <v-card>
          <v-card-title class="font-weight-bold">{{ dialog.data.id?'编辑角色':'新增角色' }}</v-card-title>
          <v-divider />
          <v-card-text class=" py-5 body-1">
            <div class="flex-1 flex-column overflow-auto mt-7 px-12 mr-12">
              <div class="mb-6 flex-align-start">
                <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2"><span class="red--text ml-1">*</span>角色类型：</div>
                <form-item
                  v-model="dialog.data.roleCategory"
                  class=" flex-1 flex-shrink-0"
                  :options="[{text:'组织角色',value:'0'},{text:'项目角色',value:'1'}]"
                  placeholder="角色类型"
                  :disabled="dialog.data.id?true:false"
                  dense
                  required
                  type="select"
                  @input="$forceUpdate()"
                  @select="(e)=>{search.data.roleCategory = e.val,roleQueryAll()}"
                />
              </div>
              <div v-if="dialog.data.roleCategory==0" class="mb-6 flex-align-start">
                <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2">关联项目角色：</div>
                <form-item
                  v-model="dialog.data.roleId"
                  class=" flex-1 flex-shrink-0"
                  :options="roleList"
                  placeholder="关联角色"
                  dense
                  type="select"
                />
              </div>
              <div class="mb-6 flex-align-start">
                <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2"><span class="red--text ml-1">*</span>角色名称：</div>
                <form-item
                  v-model="dialog.data.roleName"
                  class="mr-0"
                  placeholder="角色名称"
                  required
                  type="text"
                />
              </div>

              <div v-if="dialog.data.roleCategory==1" class="mb-6 flex-align-start">
                <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2"><span class="red--text ml-1">*</span>项目负责人默认角色：</div>
                <form-item
                  v-model="dialog.data.defaultFlag"
                  :options="[
                    {
                      text:'是',
                      value:'1'
                    },
                    {
                      text:'否',
                      value:'0'
                    }]"
                  class="mb-4"
                  direction="left min-width"
                  type="radio"
                  required
                />
              </div>
              <div v-else class="mb-6 flex-align-start">
                <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2"><span class="red--text ml-1">*</span>权限范围：</div>
                <form-item
                  v-model="dialog.data.roleScope"
                  class=" flex-1 flex-shrink-0"
                  :options="roleScopeList"
                  placeholder="权限范围"
                  dense
                  required
                  type="select"
                />
              </div>
              <div class="mb-6 flex-align-start">
                <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2"><span class="red--text ml-1">*</span>排序：</div>
                <form-item
                  v-model="dialog.data.sort"
                  class="mr-0"
                  placeholder="排序"
                  type="text"
                  required
                />
              </div>
            </div>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <div class="max-width flex-y-center flex-justify-between">
              <div class="flex-1 flex-justify-end">
                <!-- @click.stop="submitAdd()" -->
                <v-btn width="80" class depressed @click="dialog.show = false,dialog.data={}">取消</v-btn>
                <v-btn
                  width="80"
                  :loading="btnLoading"
                  class="ml-3"
                  color="primary"
                  depressed
                  type="submit"
                >确定</v-btn>

              </div>
            </div>
          </v-card-actions>
        </v-card>
      </form>
    </v-dialog>
    <!-- 新增 编辑 组织角色 end -->
    <!-- 用户列表 start -->
    <v-dialog v-model="userDialog.show" width="700">
      <v-card class=" flex-column flex-justify-between overflow-hidden" style="height:660px;">
        <v-card-title class=" flex-0 flex-shrink-0 font-weight-bold">组织角色：（{{ userDialog.text }}）</v-card-title>
        <v-divider />
        <v-card-text class=" flex-1 px-0 pb-5 overflow-auto">
          <div class="flex-1 flex-column overflow-auto ">
            <v-data-table
              :headers="userDialog.headers"
              :items="userDialog.list.slice((userDialog.search.pageSize*(userDialog.search.pageNo-1)),(userDialog.search.pageSize*userDialog.search.pageNo))"
              :items-per-page="userDialog.search.pageSize"
              class="pb-5 max-width header-grey "
              hide-default-footer
              item-key="id"
              no-data-text="暂无查询数据"
            >
              <template v-slot:item.rowIndex="{index}">
                {{ (index+1)+(userDialog.search.pageSize*(userDialog.search.pageNo-1)) }}
              </template>
            </v-data-table>
            <div class=" flex-justify-end">
              <pagination
                v-model="userDialog.search.pageNo"
                :size="userDialog.search.pageSize"
                :total="userDialog.total"
                @changePageSize="(e)=>{userDialog.search.pageSize=e,userDialog.search.pageNo=1,$forceUpdate()}"
              />
            </div>
          </div>
        </v-card-text>
        <v-divider />
        <v-card-actions class=" flex-0 flex-shrink-0">
          <div class="max-width flex-y-center flex-justify-between">
            <div class="flex-1 flex-justify-end">
              <v-btn width="80" class depressed @click="userDialog.show = false">关闭</v-btn>
            </div>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- 用户列表 end -->

    <!-- 授权 start -->
    <v-navigation-drawer
      v-model="drawer.show"
      temporary
      app
      right
      width="700"
    >
      <form v-if="drawer.show" class="max-height flex-column overflow-hidden" @submit.prevent="submit()">

        <!-- {{drawer.data.permissionIds}}=== -->
        <div class=" flex-0 flex-justify-between flex-y-center py-3 px-5" style="border-bottom:1px solid #e1e1e1">
          <span class="text-h6">{{ drawer.type=='report'?'报告授权':'菜单授权' }}</span>
          <v-icon @click="drawer.show=false,drawer.trees=[]">mdi-close</v-icon>
        </div>
        <!-- <v-tabs v-if="drawer.type!='report'" v-model="drawer.permsCategory" class=" flex-0 flex-shrink-0 border_bottom" style="border-bottom:1px solid #e1e1e1" @change="getPermissionQueryTreeList()">
          <v-tab href="#0">前台</v-tab>
          <v-tab href="#1">后台</v-tab>
        </v-tabs> -->
        <div class="flex-1 flex-column overflow-auto mt-7 px-12">
          <!-- :active.sync="drawer.active" activatable  :open.sync="drawer.open" drawer.selection -->

          <!-- <v-progress-circular
                  class=""
                  style=" position: absolute; top:50px;left:200px"
                  v-if="drawer.listLoading"
                  :size="50"
                  color="primary"
                  indeterminate
                ></v-progress-circular> -->
          <div v-if="drawer.listLoading" style="position: relative">
            <v-overlay
              :value="drawer.listLoading"
              opacity="0.1"
            >
              <v-progress-circular
                indeterminate
                size="64"
              />
            </v-overlay>
          </div>

          <v-treeview
            class=" mx-3"
            item-children="children"
            :items="drawer.trees"
            item-text="label"
            item-key="key"
            selection-type="independent"
            transition
            @update:active="chooseTree"
          >
            <!-- selectable v-model="drawer.data.permissionIds" selected-color="primary" -->
            <template v-slot:label="{item}">
              <v-checkbox
                v-model="drawer.data.permissionIds"
                class="ml-2 py-0"
                style="height:32px;margin-top:10px;"
                :label="item.label"
                :value="item.key"
                @click="testtest"
              />
            </template>
          </v-treeview>

        </div>
        <div class=" flex-0 flex-shrink-0 py-3 px-5 flex-justify-end" style="border-top:1px solid #e1e1e1;">
          <v-btn width="100" class="mr-2" depressed @click="drawer.show = false,drawer.trees=[]">取消</v-btn>
          <v-btn type="submit" :loading="btnLoading" color="primary" width="100" depressed>提交</v-btn>
        </div>
      </form>
    </v-navigation-drawer>
  </v-layout>
</template>

<script>
export default {
  data() {
    return {
      search: {
        pageSize: 10,
        pageNo: 1,
        data: {
          roleCategory: '0'
        }
      },
      total: 0,
      listLoading: false,
      btnLoading: false,
      headers: [
        { text: '序号', value: 'rowIndex', width: '50', sortable: false },
        { text: '角色名称', value: 'roleName' },
        { text: '权限范围', value: 'roleScope' },
        { text: '排序', value: 'sort' },
        { text: '状态', value: 'statusFlag' },
        { text: '操作', value: 'active', sortable: false }
      ],
      list: [],
      selected: [],
      drawer: {
        show: false,
        listLoading: false,
        data: {
          roleId: '',
          permissionChecksF: false,
          permissionIds: [],
          lastpermissionIds: []
        },
        permissionTrees: [],
        reportTrees: [],
        active: [],
        trees: [],
        open: [],
        selection: [],
        permsCategory: '0'
      },

      dialog: {
        show: false,
        type: '',
        data: {

        }
      },
      userDialog: {
        show: false,
        search: {
          pageSize: 10,
          pageNo: 1
        },
        total: 0,
        listLoading: false,
        headers: [
          { text: '序号', value: 'rowIndex', width: '50', sortable: false },
          { text: '用户账号', value: 'username' },
          { text: '姓名', value: 'realname' },
          { text: '部门/单位', value: 'orgCode' }
        ],
        list: []
      },
      roleScopeList: [
        { text: '本人参与项目', value: '0' },
        { text: '本部门负责项目', value: '1' },
        { text: '所有项目', value: '2' }
      ],
      roleList: []
    }
  },
  watch: {
    'drawer.data.permissionIds': function(newval, oldval) {
      if (this.permissionChecksF) {
        this.permissionChecksF = false
        const the = this
        let list = []
        let v = []
        if (newval.length > oldval.length) {
          list = newval
          v = oldval
        } else {
          list = oldval
          v = newval
        }
        let ff = false
        const z = (arr) => {
          arr.forEach(_z => {
            if (newval.length > oldval.length) {
              if (the.drawer.data.permissionIds.indexOf(_z.key) === -1) {
                the.drawer.data.permissionIds.push(_z.key)
              }
            } else {
              if (the.drawer.data.permissionIds.indexOf(_z.key) !== -1) {
                the.drawer.data.permissionIds.splice(the.drawer.data.permissionIds.indexOf(_z.key), 1)
              }
            }
            if (_z.children && _z.children.length) {
              z(_z.children)
            }
          })
        }
        list.forEach((item) => {
          if (v.indexOf(item) === -1) {
            const f = (arr) => {
              arr.forEach((_item) => {
                if (!ff) {
                  if (item === _item.key) {
                    ff = true
                    _item.children && _item.children.length && z(_item.children)
                  } else {
                    if (_item.children && _item.children.length) {
                      f(_item.children)
                    }
                  }
                }
              })
            }
            f(this.drawer.trees)
          }
        })
      }
    }
  },
  mounted() {
    this.initList()
    this.getPermissionQueryTreeList('init')
    this.getPermissionQueryReportTreeList()
  },
  methods: {
    // 获取关联项目角色下拉
    roleQueryAll() {
      this.$http.get(this.$api.roleListAll, { data: { roleCategory: '1' }}).then(res => {
        if (res.result && res.result.length) {
          res.result.forEach(item => {
            item.text = item.roleName
            item.value = item.id
          })
        }
        this.roleList = res.result || []
      })
    },
    initList() {
      this.search.pageNo = 1
      this.getList()
    },
    // 改变每页的条数
    changePageSize(e) {
      this.search.pageSize = e
      this.search.pageNo = 1
      this.$forceUpdate()
      this.getList()
    },
    //  获取角色列表
    getList() {
      this.total = 0
      this.listLoading = true
      const search = { ...this.search.data }
      search.pageNo = this.search.pageNo
      search.pageSize = this.search.pageSize
      this.$http.get(this.$api.roleList, { data: { ...search }}).then(res => {
        this.listLoading = false
        this.list = res.result && res.result.records || []
        this.total = res.result && res.result.total || 0
      }).catch(() => {
        this.listLoading = false
      })
    },
    // 菜单权限-获取全部的权限树
    getPermissionQueryTreeList(init) {
      this.drawer.listLoading = true
      this.$http.get(this.$api.permissionQueryTreeList, { data: { permsCategory: this.drawer.permsCategory, permsRole: this.search.data.roleCategory }}).then(res => {
        this.drawer.permissionTrees = res.result.treeList || []
        // if (init !== 'init') this.drawer.trees = res.result.treeList || []
        this.drawer.listLoading = false
      }).catch(() => {
        this.drawer.listLoading = false
      })
    },

    // 菜单权限-查询角色授权
    getPermissionQueryRolePermission(id) {
      this.$http.get(this.$api.permissionQueryRolePermission, { data: { roleId: id }}).then(res => {
        // this.drawer.selection=res.result||[]
        this.drawer.data.permissionIds = [...res.result] || []
        this.drawer.data.lastpermissionIds = [...res.result] || []
        this.$forceUpdate()
      }).catch(() => {
        this.listLoading = false
      })
    },
    // 菜单权限-获取报告权限树
    getPermissionQueryReportTreeList() {
      this.drawer.listLoading = true
      this.$http.get(this.$api.permissionQueryReportTreeList, { data: '' }).then(res => {
        this.drawer.reportTrees = res.result.treeList || []
        // this.drawer.trees=res.result||[]
        this.drawer.listLoading = false
      }).catch(() => {
        this.drawer.listLoading = false
      })
    },
    // 菜单权限-查询角色报告授权
    getPermissionQueryRoleReportPermission(id) {
      this.$http.get(this.$api.permissionQueryRoleReportPermission, { data: { roleId: id }}).then(res => {
        // this.drawer.selection=res.result||[]
        this.drawer.data.permissionIds = [...res.result] || []
        this.drawer.data.lastpermissionIds = [...res.result] || []
      }).catch(() => {
        this.listLoading = false
      })
    },

    // 切换角色类型
    changeType() {
      this.initList()
      this.getPermissionQueryTreeList()
    },
    // 用户授权弹窗
    showDrawer(id, type) {
      this.drawer.show = true
      this.drawer.data.roleId = id
      this.drawer.type = type
      // permissionTrees reportTrees
      if (type === 'report') {
        this.drawer.trees = JSON.parse(JSON.stringify(this.drawer.reportTrees))
        this.getPermissionQueryRoleReportPermission(id)
      } else {
        this.drawer.trees = JSON.parse(JSON.stringify(this.drawer.permissionTrees))
        this.getPermissionQueryRolePermission(id)
      }
    },
    // 编辑角色 弹窗
    showEdit(item) {
      this.dialog.show = true
      this.dialog.data = { ...item }
      this.dialog.data.roleCategory = '0'
      if (this.search.data.roleCategory === '1') this.dialog.data.roleCategory = '1'
      this.roleQueryAll()
    },
    showUser(e) {
      this.userDialog.total = 0
      this.userDialog.search.pageNo = 1
      this.userDialog.show = true
      this.userDialog.text = e.roleName
      this.userDialog.listLoading = true
      const param = new URLSearchParams()
      param.append('roleId', e.id)
      this.$http.get(this.$api.getRoleUserList, { data: param }).then(res => {
        this.userDialog.listLoading = false
        this.userDialog.list = res.result || []
        this.userDialog.total = this.userDialog.list.length || 0
      }).catch(() => {
        this.userDialog.listLoading = false
      })
    },
    // 操作其他按钮 确认禁用、恢复、删除
    activeList(api, method, title, par) {
      // if(confirm("是否确认"+title+"角色？")){
      //   let param = new URLSearchParams()
      //   for(let i in par){
      //     param.append(i, par[i]||'')
      //   }
      //   this.$http[method](api,{data:param}).then(res => {
      //       this.$message.success(res.message)
      //       this.initList()
      //   }).catch(()=>{
      //   });
      // }
      this.$confirm({ text: '是否确认' + title + '角色？' }).then((vm) => {
        const param = new URLSearchParams()
        for (const i in par) {
          param.append(i, par[i] || '')
        }
        vm.loading = true
        this.$http[method](api, { data: param }).then(res => {
          vm.show = false
          vm.loading = false
          this.$message.success(res.message)
          this.initList()
        }).catch(() => {
          vm.show = false
          vm.loading = false
        })
      }).catch(() => {})
    },
    // 删除角色
    showDel(id) {
      // if(confirm("是否确认删除改角色？")){
      //   this.$http.delete(this.$api.roleDelete,{data:{id:id}}).then(res => {
      //       this.$message.success(res.message)
      //       this.initList()
      //   }).catch(()=>{
      //   });
      // }
      this.$confirm({ text: '是否确认删除改角色？' }).then((vm) => {
        const param = new URLSearchParams()
        param.append('id', id || '')
        vm.loading = true
        this.$http.delete(this.$api.roleDelete, { data: param }).then(res => {
          vm.show = false
          vm.loading = false
          this.$message.success(res.message)
          this.initList()
        }).catch(() => {
          vm.show = false
          vm.loading = false
        })
      }).catch(() => {})
    },
    // 添加角色 弹出
    addShow() {
      // this.drawer.show=true
      this.dialog.data = { roleCategory: '0' }
      if (this.search.data.roleCategory === '1') this.dialog.data.roleCategory = '1'
      this.dialog.show = true
      this.roleQueryAll()
    },

    // 确认 添加 编辑角色
    submitAdd() {
      let url = this.$api.roleAdd
      let method = 'post'
      if (this.dialog.data.id) {
        url = this.$api.roleEdit
        method = 'put'
      }
      if (this.dialog.data.roleCategory === 1) {
        this.dialog.data.roleId = null
      }
      this.btnLoading = true
      this.$http[method](url, { data: { ...this.dialog.data }}).then(res => {
        this.$message.success(res.message)
        this.dialog.show = false
        this.btnLoading = false
        this.dialog.data = {}
        this.getList()
      }).catch(() => {
        this.btnLoading = false
      })
    },
    // 点击树的复选框
    testtest() {
      this.permissionChecksF = true
      // drawer.trees
    },
    // 点击用户 权限树？
    chooseTree() {},
    // 用户 授权？
    submit() {
      // permissionIds roleId
      let url = this.$api.permissionSaveRolePermission
      let _arr = null
      if (this.drawer.type === 'report') {
        url = this.$api.permissionSaveRoleReportPermission
        // _arr = new URLSearchParams()
        // // _arr.append('permissionIds', (this.drawer.data.permissionIds&&this.drawer.data.permissionIds.length)?
        // // this.drawer.data.permissionIds.join(','):'')
        // _arr.append('permissionIds', this.drawer.data.permissionIds||[])
        // _arr.append('roleId', this.drawer.data.roleId||'')
        _arr = {
          permissionIds: (this.drawer.data.permissionIds && this.drawer.data.permissionIds.length)
            ? this.drawer.data.permissionIds.join(',') : '',
          roleId: this.drawer.data.roleId || ''
        }
      } else {
        _arr = { ...this.drawer.data }
        _arr.lastpermissionIds = ''
        _arr.permissionIds = ''
        // if(this.drawer.selection&&this.drawer.selection.length) _arr=this.drawer.selection.join(',')
        if (this.drawer.data.lastpermissionIds && this.drawer.data.lastpermissionIds.length) _arr.lastpermissionIds = this.drawer.data.lastpermissionIds.join(',')
        if (this.drawer.data.permissionIds && this.drawer.data.permissionIds.length) _arr.permissionIds = this.drawer.data.permissionIds.join(',')
      }

      this.btnLoading = true
      this.$http.post(url, { data: _arr }).then(res => {
        this.$message.success(res.message)
        this.drawer.show = false
        this.btnLoading = false
        this.drawer.trees = []
        this.drawer.data = { lastpermissionIds: [], permissionIds: [] }

        // this.drawer.selection=[]
      }).catch(() => {
        this.btnLoading = false
      })
    }

  }
}
</script>

<style lang="scss" scoped>
.form-title{
    width:160px;
    color: #777;
}
// ::v-deep .theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>td:last-child, .theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>td:not(.v-data-table__mobile-row), .theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>th:last-child, .theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>th:not(.v-data-table__mobile-row), .theme--light.v-data-table>.v-data-table__wrapper>table>thead>tr:last-child>th {
//     border-bottom: thin solid rgb(156, 156, 156, .12) !important;
// }

</style>
